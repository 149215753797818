@font-face {
  font-family: 'Inter Medium';
  src: local('Inter Medium'),
    url(./assets/fonts/English/Inter-Medium-slnt=0.ttf) format('truetype');
}

.ant-popover {
  min-width: 350px;
}

.drawerHeader img.logo {
  position: absolute;
  height: 20px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

@media screen and ((max-width: 768px)) {
  .drawerHeader img.logo {
    display: none;
  }
}
